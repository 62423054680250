var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var page_data = page_data || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.preOrder = {
    attach: function () {
      var that = this;

      that.isDefaultActiveSku();
      $(document).on('isPreorder-init', function (event, skuData) {
        if (site.templates && site.templates['product_pre_order']) {
          if (!!skuData) {
            that.isPreorder(skuData);
          }
        }
      });
    },
    isPreorder: function (skuData) {
      var that = this;
      var $prodId = skuData.PRODUCT_ID;
      var $addBtn = $('.js-add-to-cart[data-product-id="' + $prodId + '"]');
      var $preWaitlistBtn = $('.js-add-to-waitlist[data-product-id="' + $prodId + '"]');
      var $activePreOrderStatus = $('[data-product-id="' + $prodId + '"] .pre_order_active_msg');
      var $tosPreOrderStatus = $('[data-product-id="' + $prodId + '"] .pre_order_out_stock_msg');
      var $soldOutPreOrderStatus = $('[data-product-id="' + $prodId + '"] .pre_order_sold_out_msg');
      var $prodContainer = $('.js-inv-status-list[data-product-id="' + $prodId + '"]');
      var $preOrderStatus = $('.pre-order__status[data-product-id="' + $prodId + '"]');
      var $soldOut = $('.sold-out');
      var $quickShop = $('.js-quickshop').length;

      $addBtn.removeClass('.hidden');
      if (skuData.isPreOrder === 1) {
        $preOrderStatus.removeClass('hidden');
        $prodContainer.addClass('hidden');
        if ($quickShop) {
          $preOrderStatus.addClass('mpp_pre_order_status');
        }
        if (skuData.INVENTORY_STATUS === 1) {
          that.addHidden($tosPreOrderStatus, $soldOutPreOrderStatus);
          $activePreOrderStatus.removeClass('hidden');
          $addBtn.addClass('pre-order-active');
          $addBtn.text(site.translations.product.pre_order);
          $activePreOrderStatus.text(site.translations.product.pre_order_active_msg);
          that.setPreorderMessage($activePreOrderStatus, site.translations.product.pre_order_active_msg, skuData, true);
        } else if (skuData.INVENTORY_STATUS === 2) {
          that.addHidden($activePreOrderStatus, $soldOutPreOrderStatus, $soldOut);
          $tosPreOrderStatus.removeClass('hidden');
          $addBtn.removeClass('pre-order-active');
          $preWaitlistBtn.addClass('hidden');
          $addBtn.text(site.translations.product.pre_order);
          $tosPreOrderStatus.text(site.translations.product.pre_order_out_stock_msg);
          that.setPreorderMessage(
            $tosPreOrderStatus,
            site.translations.product.pre_order_out_stock_msg,
            skuData,
            false
          );
        } else if (skuData.INVENTORY_STATUS === 7) {
          that.addHidden($activePreOrderStatus, $tosPreOrderStatus, $soldOut);
          $soldOutPreOrderStatus.removeClass('hidden');
          $addBtn.removeClass('hidden');
          $addBtn.removeClass('pre-order-active');
          $preWaitlistBtn.addClass('hidden');
          $addBtn.text(site.translations.product.pre_order);
          $soldOutPreOrderStatus.text(site.translations.product.pre_order_sold_out_msg);
          that.setPreorderMessage(
            $soldOutPreOrderStatus,
            site.translations.product.pre_order_sold_out_msg,
            skuData,
            false
          );
        }
      } else {
        $addBtn.text(site.translations.product.product_cta_add_to_bag);
        $preOrderStatus.addClass('hidden');
        $addBtn.removeClass('pre-order-active');
        $soldOut.removeClass('hidden');
        $prodContainer.removeClass('hidden');
      }
    },
    addHidden: function () {
      $.each(arguments, function (i, element) {
        if (element !== undefined) {
          element.addClass('hidden');
        }
      });
    },
    isDefaultActiveSku: function () {
      var isSearch = $('body').hasClass('section-esearch');

      if (!isSearch) {
        var prodList = prodcat.data.store.products;

        $.each(prodList, function (index, sku) {
          if (!!sku && !!sku.defaultSku) {
            var $prodId = sku.PRODUCT_ID;
            var $defSku = sku.defaultSku['isPreOrder'];
            var skuList = sku.skus;
            var $preorderCount = 0;

            $.each(skuList, function (key, value) {
              var skuData = prodcat.data.getSku(value);

              if (skuData.isPreOrder === 1) {
                $preorderCount++;
              }
            });
            if ($defSku === 1 || skuList.length === $preorderCount) {
              var $PreOrderStatus = $('[data-product-id="' + $prodId + '"]').find(
                '.product-brief__cta--shop-now ,.product-brief__cta--select-sku'
              );

              $PreOrderStatus.addClass('pre-order-active');
              $PreOrderStatus.text(site.translations.product.pre_order);
            }
          }
        });
      }
    },
    setPreorderMessage: function ($ele, trKey, dateStr, calculateShipDays) {
      if (dateStr) {
        var releaseSkuDateStr = '' + dateStr.RELEASE_DATE;
        var releaseSkuDate = new Date();

        releaseSkuDate.setFullYear(
          parseInt('20' + releaseSkuDateStr.substr(0, 2)),
          parseInt(releaseSkuDateStr.substr(2, 2)) - 1,
          parseInt(releaseSkuDateStr.substr(4, 2))
        );
        if (calculateShipDays) {
          var shipDays = parseInt(site.translations.product.preorder_ships_by_days);
          var days = releaseSkuDate.getDate();

          if (!isNaN(shipDays)) {
            releaseSkuDate.setDate((days += shipDays));
          }
        }
        var releaseDay = ('0' + releaseSkuDate.getDate()).slice(-2);
        var releaseMonth = ('0' + (releaseSkuDate.getMonth() + 1)).slice(-2);
        var releaseDateReplace = releaseMonth + '/' + releaseDay;

        $ele.html(trKey);
        var msg = $ele.html().replace(/::rs_dt::/, releaseDateReplace);

        $ele.html(msg);
        $ele.show();
      }
    }
  };
})(jQuery);
